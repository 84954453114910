<template>
  <div class="menu">
    <div @click="toggleCollapsed" class="toggle-menu">
      <svg class="icon" aria-hidden="true">
        <use :xlink:href="'#icon-' + (state.collapsed ? 'zhankai' : 'shouqi')" />
      </svg>
    </div>
    <div class="logo" :class="state.collapsed ? 'small-logo' : 'large-logo'">
      <img src="../assets/images/img/artemisads-logo.svg" />
    </div>
    <div class="content">
      <a-menu mode="inline" :items="menus" @click="onClick" v-model:openKeys="state.openKeys"
        v-model:selectedKeys="state.selectedKeys" :inline-collapsed="state.collapsed" />
      <div class="bottom-entries" v-if="bottomEntries.length">
        <a-menu mode="inline" :items="bottomEntries" @click="onClick" v-model:openKeys="state.openKeys"
          v-model:selectedKeys="state.selectedKeys" :inline-collapsed="state.collapsed" />
      </div>
    </div>
    <contact-us-modal v-if="showContactUsModal" :open="showContactUsModal" @hide="showContactUsModal = false" />
  </div>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router'
import { ref, onMounted, reactive, watch } from 'vue'
import ContactUsModal from './ContactUsModal.vue'
const {
  menus,
  bottomEntries = [],
  userType = 'publisher',
} = defineProps(['userType', 'menus', 'bottomEntries'])
const route = useRoute()
const router = useRouter()
const state = ref({
  collapsed: false,
  selectedKeys: [userType === 'admin' ? 'operator/users' : `/${userType}/products`],
})
const showContactUsModal = ref(false)

function getSelected() {
  let key
  let arr = route.path.split('/')
  let temp = []

  menus.concat(bottomEntries).forEach((v) => {
    temp.push(v)

    if (v.children) {
      v.children.forEach((child) => {
        temp.push(child)
      })
    }
  })

  if (route.path.indexOf('/brand/detail/') !== -1) {
    arr[2] = 'brand-index'
  } else if (arr.length == 4 && !/\d/g.test(arr[3])) {
    arr[2] = arr[2] + '-' + arr[3]
  }

  for (let v of temp) {
    if (v.routeName == arr[2]) {
      key = v.key
      state.value.openKeys = []

      if (v.preKey) {
        state.value.openKeys.push(v.preKey)
      }

      break
    }
  }

  state.value.selectedKeys = [key]
}

function onClick({ key }) {
  const preSelectedKeys = state.value.selectedKeys
  if (key.includes('/')) {
    router.push(key)
  } else if (key === 'contact-us') {
    showContactUsModal.value = true
    state.value.selectedKeys = [...preSelectedKeys]
  }
}

function toggleCollapsed() {
  state.value.collapsed = !state.value.collapsed
  state.value.openKeys = state.value.collapsed ? [] : state.value.preOpenKeys
}

onMounted(() => {
  getSelected()
})

watch(
  () => state.openKeys,
  (_val, oldVal) => {
    state.value.preOpenKeys = oldVal
  }
)

watch(
  () => route.path,
  (val) => {
    getSelected()
  }
)
</script>

<style lang="less" scoped>
.menu {
  height: 100%;
  display: flex;
  padding-top: 24px;
  position: relative;
  flex-direction: column;
  background: var(--color-white);
  border-right: 1px solid var(--border-4);

  .toggle-menu {
    top: 10px;
    z-index: 4;
    width: 24px;
    right: -24px;
    height: 28px;
    cursor: pointer;
    position: absolute;
    background: #ffffff;
    border: 1px solid var(--border-4);
    box-shadow: 0 1px 0 0 var(--box-shadow-2);
    border-radius: 0 8px 8px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--dark-3);

    .icon {
      font-size: 12px;
    }

    &:hover {
      color: var(--dark-3);
      background-color: var(--bg-gray-1);
      border-color: var(--border-1);
    }

    &:active {
      color: var(--dark-2);
      background-color: var(--bg-gray-2);
      border-color: var(--bg-gray-7);
    }
  }

  .logo {
    width: 60px;
    height: 40px;
    min-height: 40px;
    overflow: hidden;
    transition: width 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    position: relative;

    img {
      top: 0;
      left: 16px;
      width: 176px;
      height: 40px;
      min-width: 176px;
      position: absolute;
    }
  }

  .large-logo {
    width: 232px;
  }
}

.content {
  height: 100%;
  display: flex;
  margin: 16px 8px 0;
  flex-direction: column;
  justify-content: space-between;

  :deep(.ant-menu) {
    border-inline-end: none;

    .reports-beta {
      >span {
        padding: 1.5px 4px;
        width: 41px;
        background: var(--bg-gray-8);
        border-radius: 4px;
        font-weight: 500;
        color: var(--dark-3);
        font-size: 12px;
        letter-spacing: 0.3px;
        line-height: 16px;
      }
    }

    &.ant-menu-inline-collapsed {
      width: 56px !important;
    }

    &-submenu-arrow {
      color: var(--dark-3) !important;
      transform: translateY(-50%) !important;
    }

    &-item,
    &-submenu-title {
      height: 40px;
      display: flex;
      font-size: 15px;
      font-weight: 500;
      line-height: 20px;
      position: relative;
      align-items: center;
      border-radius: 50px;
      color: var(--dark-1);
      letter-spacing: 0.4px;
      border-radius: 8px !important;
      padding-left: 12px !important;

      &:hover {
        background-color: var(--bg-gray-6) !important;
      }

      &:active {
        background-color: var(--bg-gray-4) !important;
      }

      &-icon {
        height: 40px;
        flex: 0 0 24px;
        font-size: 24px;
        margin-right: 2px;
      }

      &-selected {
        opacity: 1;
        background-color: var(--primary-selectd);
        color: var(--primary-text);
        border-radius: 50px;
        font-weight: 600;

        &:hover {
          color: var(--primary-text) !important;
          background-color: var(--primary-selectd) !important;
        }

        &::after {
          top: 10px;
          left: 0;
          width: 3px;
          height: 20px;
          position: absolute;
          border-radius: 50px;
          background-color: var(--primary-text);
        }

        .ant-menu-item-icon {
          opacity: 1;
          font-weight: 500;
          color: var(--primary-text);
        }
      }

      &.ant-menu-item-only-child {
        padding-left: 48px !important;

        &:last-of-type {
          margin-block-end: unset;
        }
      }
    }

    &-submenu-title {
      margin-block-end: unset;
      margin-block-start: unset;
      padding-left: 12px !important;
    }

    .ant-menu-submenu {
      margin-block: 4px;

      .ant-menu-submenu-arrow {
        &::after {
          top: -8px;
          display: block;
          font-size: 14px;
          content: '\e75f';
          transform: unset;
          font-style: normal;
          border-radius: unset;
          font-family: 'iconfont';
          background-color: unset;
        }
      }

      .ant-menu-sub {
        background: unset;
      }
    }

    .ant-menu-item-icon {
      height: 40px;
      font-size: 24px;
      margin-right: 2px;
    }

    .ant-menu-submenu-arrow {

      &::before,
      &::after {
        width: 0;
        transform: unset;
      }
    }

    &-submenu-open {
      .ant-menu-submenu-arrow {
        &::after {
          top: -6px;
          display: block;
          font-size: 14px;
          content: '\e71f';
          transform: unset;
          font-style: normal;
          border-radius: unset;
          font-family: 'iconfont';
          background-color: unset;
        }
      }
    }
  }
}

.bottom-entries {
  padding-bottom: 12px;

  :deep(.ant) {
    &-menu {
      &-item {
        padding-left: 12px !important;

        >.icon {
          width: 24px;
          height: 24px;
          padding: 4px;
          font-size: 16px;
          line-height: 24px;
          margin-right: 12px;
          text-align: center;
          border-radius: 6px;
          color: var(--dark-1);
          display: inline-block;
          background: var(--yellow-1);

          &-help {
            background: var(--bg-gray-4);
          }

          &-contact-us {
            background: var(--bg-gray-4);
          }
        }

        &-selected {
          opacity: 1;
          font-weight: 500;
          color: var(--dark-1);
          background: none;

          &:hover {
            color: var(--dark-1) !important;
            background-color: var(--bg-gray-6) !important;
          }

          &:active {
            background-color: var(--bg-gray-4) !important;
          }

          &:after {
            display: none;
          }
        }
      }

      &-title-content {
        margin-inline-start: 0 !important;
      }
    }
  }
}
</style>